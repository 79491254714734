<template>
  <div class="footer">
    <div class="footer-box">
      <div class="left">
        <h1>华凤技术（南京）有限公司</h1>
        <h2>{{ companyInfo.phone }}</h2>
        <p>热线电话</p>
      </div>
      <div class="center">
        <div class="menu" v-for="(item, index) in menuList" :key="index">
          <div class="menu-title">{{ item.name }}</div>
          <div
            class="menu-item"
            v-for="(item2, index2) in item.menu"
            :key="index2"
            @click="goUrl(item2)"
          >
            {{ item2.name }}
          </div>
        </div>
      </div>
      <div class="right">
        <div>
          <img src="@/assets/img/qrcode1.png" alt="" />
          <p>技术施工代理人</p>
        </div>
        <div>
          <img src="@/assets/img/qrcode2.png" alt="" />
          <p>项目开发代理人</p>
        </div>
        <div>
          <img src="@/assets/img/qrcode.jpg" alt="" />
          <p>官方微信公众号</p>
        </div>
      </div>
    </div>
    <div class="footer_bottom">
      <div class="left">
        Copyright © 2006-2024 HuaFeng All Rights Reserved.
        <span>苏ICP备2024058247号-2</span>
      </div>
      <div class="right">
        <div class="imgbox" @click="goIndex()"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companyInfo: JSON.parse(sessionStorage.getItem("companyInfo")) || {},
      companyList: JSON.parse(sessionStorage.getItem("companyList")) || [],
      menuList: [
        {
          name: "企业优势",
          menu: [
            {
              name: "业务优势",
              path: "/businessAdvantages",
            },
            {
              name: "综合优势",
              path: "/comprehensiveAdvantages",
            },
          ],
        },
        {
          name: "项目案例",
          menu: [
            {
              name: "预建设",
              path: "/preConstruction",
            },
            {
              name: "综合案例",
              url: "",
            },
            {
              name: "在线设计",
              url: "",
            },
          ],
        },
        {
          name: "新闻中心",
          menu: [
            {
              name: "公司新闻",
              path: "/companyNews",
            },
            {
              name: "行业资讯",
              path: "/industryNews",
            },
            {
              name: "最新动态",
              path: "/news",
            },
          ],
        },
        {
          name: "了解更多",
          menu: [
            {
              name: "关于我们",
              path: "/aboutUs",
            },
            {
              name: "加入我们",
              path: "/joinUs",
            },
            {
              name: "联系我们",
              path: "/contactUs",
            },
            {
              name: "代理合作",
              path: "/agentCooperation",
            },
            {
              name: "数字画册",
              url: "https://flbook.com.cn/c/FPafNHcLpl",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("active");
          } else {
          }
        });
      });

      document.querySelectorAll(".animate-up").forEach((element) => {
        observer.observe(element);
      });
    });
  },
  methods: {
    goIndex() {
      if (this.$route.path != "/" && this.$route.path !== "index") {
        this.$router.push({ path: "/" });
      }
    },
    goUrl(item) {
      if (item.path) {
        this.$router.push({ path: item.path });
      } else if (item.url) {
        window.open(item.url, "_blank");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: block;
  width: calc(100vw - 320px);
  margin: auto;
  background: #111214;
  letter-spacing: normal;
  position: relative;
  left: -160px;
  padding: 0 160px;
  .footer-box {
    display: flex;
    flex-wrap: nowrap;
    padding: 70px 0;
    .left {
      border-right: 1px solid #666;
      padding: 0 100px 0 0;
      h1 {
        font-size: 30px;
        font-weight: 700;
        line-height: 30px;
        margin: 0 0 90px;
      }
      h2 {
        font-size: 28px;
        font-weight: 400;
        line-height: 28px;
        margin: 0 0 16px;
      }
      p {
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        letter-spacing: normal;
      }
    }
    .center {
      flex: 1;
      width: 0;
      padding: 0 0 0 100px;
      display: flex;
      flex-wrap: nowrap;

      .menu {
        margin: 0 96px 0 0;
        .menu-title {
          font-size: 18px;
          font-weight: 400;
          line-height: 22px;
          margin: 0 0 32px;
        }
        .menu-item {
          cursor: pointer;
          font-size: 14px;
          line-height: 16px;
          margin: 0 0 24px;
          color: #999;
          letter-spacing: normal;
          &:hover {
            color: #009ade;
          }
          &:last-child {
            margin: 0;
          }
        }
      }
    }
    .right {
      text-align: center;
      display: flex;
      flex-wrap: nowrap;
      div {
        margin: 0 0 0 16px;
      }
      img {
        display: block;
        width: 96px;
        height: 96px;
        margin: auto;
      }
      p {
        font-size: 14px;
        line-height: 14px;
        margin: 20px 0 0;
        font-weight: normal;
        letter-spacing: normal;
      }
    }
  }
  .footer_bottom {
    border-top: 1px solid #666;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 61px;
    align-items: center;
    .left {
      color: #999;
      font-size: 14px;
      line-height: 14px;
      span {
        // cursor: pointer;
        margin: 0 0 0 12px;
      }
    }
    .right {
      .imgbox {
        width: 83px;
        height: 30px;
        cursor: pointer;
        background-image: url("~@/assets/img/logo2.png");
        background-size: cover;
        &:hover {
          background-image: url("~@/assets/img/logo3.png");
        }
      }
    }
  }
}
</style>
